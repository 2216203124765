<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden z-50" @close="open = false" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        {{ $t('device.edit') }}<br />
                        {{ deviceType }}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ifgreen" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 relative flex-1 px-4 sm:px-6">
                    <div v-if="organisation && !device.installation">
                      <label class="block mb-1 text-sm font-medium text-gray-700">Marka urządzenia</label>
                      <OrganisationSelect :global="false" :default="device.organisation.id" @change="udpateOrganisation" />
                    </div>
                    <TemplateForm
                      v-model="parameters"
                      :schema="this.device.schemaVersion.parametersSchema"
                      :button="false"
                    />
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="open = false">
                    Anuluj
                  </button>
                  <button @click="editDevice" type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
                    Zapisz
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import TemplateForm from '@/components/admin/DeviceTemplate/TemplateForm.vue'
import OrganisationSelect from '@/components/admin/OrganisationSelect.vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { mapState } from 'vuex'
import { api } from "@/assets/js/api-client.js";

export default {

    components: {
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
      XMarkIcon,
      TemplateForm,
      OrganisationSelect
    },
    emits: ['edit-device-params'],
    props: {
      device: Object,
      tabToRefresh: String,
      production: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        open: false,
        parameters: {},
        organisation: null,
        paramsDiff: [],
      }
    },
    computed: {
      ...mapState(['specialDeviceTemplates']),
      deviceType() {
        const haveTranslatedName = this.device.deviceTemplate.nameTranslations && this.device.deviceTemplate.nameTranslations[this.$i18n.locale]
        return haveTranslatedName ? this.device.deviceTemplate.nameTranslations[this.$i18n.locale] : this.device.deviceTemplate.name
      }
    },
    methods: {
      udpateOrganisation (id) {
        this.organisation = id
      },
      show () {
        this.paramsDiff = []
        this.organisation = this.device.organisation ? this.device.organisation.id : null 
        this.parameters = Object.assign({}, this.device.parameters)
        this.open = true
      },
      async editDevice () {
        if (this.production) {
          const parameters = this.parameters
          delete parameters.validated

          const template = this.specialDeviceTemplates.find(template => {return template.organisation.id == this.organisation})

          const device = {
            parameters,
            organisation: `/organisations/${this.organisation}`,
            deviceTemplate: `/device_templates/${template.id}`,
            schemaVersion: `/device_template_schema_versions/${template.activeVersion.id}`
          }

          this.$emit('edit-device-params', {id: this.device.id, device})
        } else {
          this.$store.dispatch('setLoading', true)
          for (const param of this.paramsDiff) {
            await api.post('/device_parameters', param)
          }
          await this.$store.dispatch('getCollection', {entity: 'installations', status: this.tabToRefresh})
          this.$store.dispatch('setLoading', false)
        }  
        this.$nextTick(() => {this.open = false})
      }
    },
    watch: {
      parameters: {
        deep: true,
        handler () {
          this.paramsDiff = []
          this.device.schemaVersion.parametersSchema.forEach(field => {
            if (this.parameters[field.name] !== this.device.parameters[field.name]) {
              this.paramsDiff.push({device: `/custom_devices/${this.device.id}`, name: field.name, value: this.parameters[field.name]})
            }
          })
          console.log(this.paramsDiff)
        }
      }
    }
}
</script>
